import React, { useState, useEffect, ReactNode, useCallback } from 'react';
import { ChartBar, Cog, PieChart, BarChart, FileSpreadsheet, TrendingUp, Clock, ShoppingBag, Coffee, Tv, Apple, Mail, MapPin, Moon, Sun, Zap, Menu, X } from 'lucide-react';
import { Users, Award, Wind, Globe, Brain, Rocket, Wrench, Code, Database, Shield, GitBranch } from 'lucide-react';
import { useInView } from 'react-intersection-observer';
import { createClient } from '@supabase/supabase-js';
import HeroSection from './HeroSection';
import GoogleTagManager from './GoogleTagManager'; 
import Cal, { getCalApi } from "@calcom/embed-react";


const supabase = createClient('https://qpzpynfspiuhozwzleiq.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InFwenB5bmZzcGl1aG96d3psZWlxIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjM4NDIwNTAsImV4cCI6MjAzOTQxODA1MH0.oOCkVamMcwolkIX8hx6IRUPK38-wLC0U00aYmRmjaT4');

declare global {
  interface Window {
    ym: (counterId: number, action: string, parameters?: any) => void;
  }
}

function gtag(...args: any[]) {
  (window as any).dataLayer.push(arguments);
}


const YandexMetrika: React.FC = () => {
  useEffect(() => {
    // Create a script element
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.text = `
      (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
      m[i].l=1*new Date();
      for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
      k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
      (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");
      ym(98042431, "init", {
        clickmap:true,
        trackLinks:true,
        accurateTrackBounce:true,
        webvisor:true
      });
    `;

    // Append the script to the document body
    document.body.appendChild(script);

    // Clean up function
    return () => {
      document.body.removeChild(script);
    };
  }, []); // Empty dependency array ensures this runs once on mount

  return null; // This component doesn't render anything
};

const LandingPage: React.FC = () => {
  const [darkMode, setDarkMode] = useState<boolean>(false);
  
  const [email, setEmail] = useState<string>('');
  const [subscribeStatus, setSubscribeStatus] = useState<string>('');

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  
  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://assets.calendly.com/assets/external/widget.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);



  const handleEmailSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSubscribeStatus('Subscribing...');

    try {
      const { data, error } = await supabase
        .from('subscribers')
        .insert([{ email: email }]);

      if (error) throw error;

      console.log('Subscription successful:', data);
      setEmail('');
      setSubscribeStatus('Thank you for subscribing!');
    } catch (error) {
      console.error('Error subscribing:', error);
      setSubscribeStatus('An error occurred. Please try again.');
    }

    setTimeout(() => setSubscribeStatus(''), 3000);
  };

  const CalWidget: React.FC = React.memo(() => {
    useEffect(() => {
      (async function () {
        const cal = await getCalApi({ "namespace": "30min" });
        cal("ui", {
          "theme": "light",
          "styles": { "branding": { "brandColor": "#0060cf" } },
          "hideEventTypeDetails": false,
          "layout": "month_view"
        });
      })();
    }, []);

    return (
      <Cal
        namespace="30min"
        calLink="ilya-golubev-financial-expert/30min"
        style={{ width: "100%", height: "580px", overflow: "scroll" }}
        config={{ "layout": "column_view", "theme": "light" }}
      />
    );
  });

  const EmailSubscriptionForm: React.FC = () => {
    const [email, setEmail] = useState<string>('');
    const [subscribeStatus, setSubscribeStatus] = useState<string>('');

    const handleEmailSubmit = useCallback(async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      setSubscribeStatus('Subscribing...');

      try {
        const { data, error } = await supabase
          .from('subscribers')
          .insert([{ email: email }]);

        if (error) throw error;

        console.log('Subscription successful:', data);
        setEmail('');
        setSubscribeStatus('Thank you for subscribing!');

        // Google Ads Conversion Tracking for Email Subscription
        gtag('event', 'conversion', {
          'send_to': 'AW-16676678730/9P0iCIDTzssZEMrYh5A-', // Replace with your actual conversion ID and label
          'event_callback': function() {
            console.log('Conversion sent to Google Ads');
          }
        });



      } catch (error) {
        console.error('Error subscribing:', error);
        setSubscribeStatus('An error occurred. Please try again.');
      }

      setTimeout(() => setSubscribeStatus(''), 3000);
    }, [email]);

    return (
      <form onSubmit={handleEmailSubmit} className="flex flex-col">
        <div className="flex mb-2">
          <input
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="flex-grow px-3 py-2 text-gray-700 rounded-l-md focus:outline-none"
            required
          />
          <button
            type="submit"
            className="bg-blue-600 text-white px-4 py-2 rounded-r-md hover:bg-blue-700 transition duration-300"
          >
            Subscribe
          </button>
        </div>
        {subscribeStatus && (
          <p className={`text-sm ${subscribeStatus.includes('error') ? 'text-red-400' : 'text-green-400'}`}>
            {subscribeStatus}
          </p>
        )}
      </form>
    );
  };


  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [darkMode]);

  useEffect(() => {
    // Add global styles
    const style = document.createElement('style');
    style.textContent = `
      @keyframes spin-slow {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }
      .animate-spin-slow {
        animation: spin-slow 10s linear infinite;
      }
      @keyframes float {
        0%, 100% { transform: translateY(0px); }
        50% { transform: translateY(-20px); }
      }
      .animate-float {
        animation: float 5s ease-in-out infinite;
      }
      @keyframes pulse-opacity {
        0%, 100% { opacity: 1; }
        50% { opacity: 0.6; }
      }
      .animate-pulse-opacity {
        animation: pulse-opacity 3s ease-in-out infinite;
      }
    `;
    document.head.appendChild(style);

    // Cleanup function to remove the style when the component unmounts
    return () => {
      document.head.removeChild(style);
    };
  }, []); // Empty dependency array means this effect runs once on mount and cleanup on unmount

  const scrollTo = (id: string): void => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };


  interface AnimatedSectionProps {
    children: ReactNode;
    id: string;
  }

  const AnimatedSection: React.FC<AnimatedSectionProps> = ({ children, id }) => {
    const { ref, inView } = useInView({
      threshold: 0.2,
      triggerOnce: true,
    });

    return (
      <div
        ref={ref}
        id={id}
        className={`transition-all duration-1000 ease-out ${
          inView ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'
        }`}
      >
        {children}
      </div>
    );
  };

  const MobileMenu: React.FC<{ isOpen: boolean; onClose: () => void; onNavigate: (section: string) => void }> = ({ isOpen, onClose, onNavigate }) => {
    return (
      <div 
        className={`fixed inset-0 z-50 transform transition-transform duration-300 ${
          isOpen ? 'translate-x-0' : 'translate-x-full'
        }`}
      >
        <div className="absolute inset-0 bg-black opacity-50" onClick={onClose}></div>
        <div className="absolute right-0 h-full w-64 bg-white dark:bg-gray-800 shadow-xl">
          <div className="p-6">
            <button 
              onClick={onClose}
              className="absolute top-4 right-4 text-gray-600 dark:text-gray-300"
            >
              <X className="h-6 w-6" />
            </button>
            <ul className="space-y-4 mt-8">
              {['Services', 'Industries', 'About', 'Contact'].map((item) => (
                <li key={item}>
                  <button
                    onClick={() => {
                      onNavigate(item.toLowerCase());
                      onClose();
                    }}
                    className="w-full text-left py-2 text-gray-600 dark:text-gray-300 hover:text-blue-600 dark:hover:text-blue-400"
                  >
                    {item}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={`min-h-screen font-sans ${darkMode ? 'dark' : ''}`}>
      {/* Header */}
      <header className="bg-white dark:bg-gray-800 shadow-sm fixed w-full z-10 transition-all duration-300 ease-in-out" style={{
        background: darkMode ? 'rgba(31, 41, 55, 0.8)' : 'rgba(255, 255, 255, 0.8)',
        backdropFilter: 'blur(5px)'
      }}>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4 flex justify-between items-center">
        <div className="flex items-center">
          <img 
            src="/logo512_clear.png" 
            alt="Hermes BI Logo" 
            className="h-8 w-8 mr-2 animate-pulse-opacity" 
          />
          <span className="text-xl font-bold text-gray-900 dark:text-white">Hermes BI</span>
        </div>
          <nav className="flex items-center">
            {/* Desktop Menu */}
            <ul className="hidden md:flex space-x-6 mr-6">
              {['Services', 'Industries', 'About', 'Contact'].map((item, index) => (
                <li key={index}>
                  <a
                    href={`#${item.toLowerCase()}`}
                    onClick={(e) => {
                      e.preventDefault();
                      scrollTo(item.toLowerCase());
                    }}
                    className="text-gray-600 dark:text-gray-300 hover:text-blue-600 dark:hover:text-blue-400 transition duration-300"
                  >
                    {item}
                  </a>
                </li>
              ))}
            </ul>
            
            {/* Mobile Menu Button */}
            <button
              onClick={() => setMobileMenuOpen(true)}
              className="md:hidden mr-4 text-gray-600 dark:text-gray-300"
            >
              <Menu className="h-6 w-6" />
            </button>

            {/* Theme Toggle Button */}
            <button
              onClick={() => setDarkMode(!darkMode)}
              className="p-2 rounded-full bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-200 transition duration-300"
            >
              {darkMode ? <Sun className="h-5 w-5" /> : <Moon className="h-5 w-5" />}
            </button>
          </nav>
        </div>
      </header>

      {/* Mobile Menu */}
      <MobileMenu 
        isOpen={mobileMenuOpen} 
        onClose={() => setMobileMenuOpen(false)}
        onNavigate={scrollTo}
      />

      {/* Hero Section */}
      <AnimatedSection id="hero">
        <HeroSection onScrollTo={scrollTo} />
      </AnimatedSection>
      
      <AnimatedSection id="pain_points">
        <section className="py-20 bg-gradient-to-b from-gray-50 to-white dark:from-gray-800 dark:to-gray-900">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center mb-16">
              <h2 className="text-4xl font-bold mb-4 bg-gradient-to-r from-blue-600 to-indigo-600 bg-clip-text text-transparent">
                Common Challenges We Solve
              </h2>
              <p className="text-gray-600 dark:text-gray-300 text-lg max-w-2xl mx-auto">
                Transform your financial processes from time-consuming tasks to strategic advantages
              </p>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {[
                {
                  icon: Clock,
                  title: "Time-Consuming Processes",
                  description: "Manual reporting draining your team's productivity?",
                  solutions: [
                    "Automate 70% of reporting processes",
                    "Real-time data consolidation",
                    "Automated variance analysis",
                    "Smart exception handling"
                  ],
                  color: "from-blue-400 to-blue-600",
                  metric: "Save 40+ hours monthly"
                },
                {
                  icon: Shield,
                  title: "Data Quality & Control",
                  description: "Struggling with data accuracy and consistency?",
                  solutions: [
                    "Automated validation checks",
                    "Data reconciliation tools",
                    "Audit trail automation",
                    "Version control systems"
                  ],
                  color: "from-indigo-400 to-indigo-600",
                  metric: "Reduce errors by 95%"
                },
                {
                  icon: Brain,
                  title: "Limited Analytics",
                  description: "Missing insights for strategic decisions?",
                  solutions: [
                    "Predictive analytics",
                    "Dynamic dashboards",
                    "Drill-down capabilities",
                    "Custom KPI tracking"
                  ],
                  color: "from-purple-400 to-purple-600",
                  metric: "Real-time insights"
                },
                {
                  icon: TrendingUp,
                  title: "Working Capital Management",
                  description: "Cash tied up in inefficient operations?",
                  solutions: [
                    "Inventory aging analysis",
                    "Payment terms optimization",
                    "Cash conversion cycle tracking",
                    "Stock obsolescence prevention"
                  ],
                  color: "from-green-400 to-green-600",
                  metric: "15-30% WC reduction"
                },
                {
                  icon: Database,
                  title: "System Integration",
                  description: "Multiple systems causing data silos?",
                  solutions: [
                    "Automated data extraction",
                    "Cross-system reconciliation",
                    "Unified reporting platform",
                    "API integrations"
                  ],
                  color: "from-red-400 to-red-600",
                  metric: "Single source of truth"
                },
                {
                  icon: ChartBar,
                  title: "Forecasting Accuracy",
                  description: "Need better predictive capabilities?",
                  solutions: [
                    "ML-based forecasting",
                    "Scenario modeling",
                    "Trend analysis",
                    "Variance tracking"
                  ],
                  color: "from-yellow-400 to-yellow-600",
                  metric: "90%+ accuracy"
                }
              ].map((item, index) => (
                <div 
                  key={index} 
                  className="relative group bg-white dark:bg-gray-900 p-8 rounded-xl shadow-lg hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1 overflow-hidden"
                >
                  <div className={`absolute inset-0 bg-gradient-to-r ${item.color} opacity-0 group-hover:opacity-5 rounded-xl transition-opacity duration-300`}></div>
                  
                  {/* Top Section */}
                  <div className="flex items-start mb-6">
                    <div className="bg-blue-100 dark:bg-blue-900/30 p-3 rounded-lg">
                      <item.icon className="h-6 w-6 text-blue-600 dark:text-blue-400" />
                    </div>
                    <div className="ml-4">
                      <h3 className="text-xl font-bold text-gray-900 dark:text-white mb-2">
                        {item.title}
                      </h3>
                      <p className="text-gray-600 dark:text-gray-400">
                        {item.description}
                      </p>
                    </div>
                  </div>

                  {/* Solutions List */}
                  <ul className="space-y-3 mb-6">
                    {item.solutions.map((solution, idx) => (
                      <li key={idx} className="flex items-center text-gray-600 dark:text-gray-400">
                        <span className="h-1.5 w-1.5 bg-blue-600 dark:bg-blue-400 rounded-full mr-2"></span>
                        {solution}
                      </li>
                    ))}
                  </ul>

                  {/* Metric Badge */}
                  <div className="absolute bottom-4 right-4">
                    <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-blue-100 dark:bg-blue-900/30 text-blue-600 dark:text-blue-400">
                      {item.metric}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </AnimatedSection>

      {/* Services Section */}
      <AnimatedSection id="services">
        <section className="py-16 bg-white dark:bg-gray-900">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <h2 className="text-3xl font-bold text-center mb-12 text-gray-900 dark:text-white">Where We Can Help</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {[
              { 
                icon: ChartBar, 
                title: "Management Reporting Optimization", 
                description: "Transform your monthly reporting process for better efficiency and insights.",
                features: [
                  "Streamline data consolidation workflows",
                  "Design real-time KPI dashboards",
                  "Build customized reporting templates",
                  "Implement automated variance analysis"
                ],
                potential: "Potential to reduce month-end closing time by 70% through smart automation and validation."
              },
              { 
                icon: Cog, 
                title: "Process Automation", 
                description: "Minimize manual work while enhancing accuracy.",
                features: [
                  "Automate data extraction processes",
                  "Set up system reconciliations",
                  "Create automated analysis workflows",
                  "Implement exception-based reporting"
                ],
                potential: "Can eliminate up to 80% of manual reconciliation work, saving dozens of hours monthly."
              },
              { 
                icon: TrendingUp, 
                title: "Performance Analytics", 
                description: "Transform your data into actionable insights.",
                features: [
                  "Design margin analysis frameworks",
                  "Optimize working capital tracking",
                  "Develop cash flow forecasting models",
                  "Create cost center analytics"
                ],
                potential: "Help identify margin improvement opportunities through detailed analytics and visualization."
              },
              { 
                icon: Shield, 
                title: "Control & Compliance", 
                description: "Strengthen accuracy while maintaining control.",
                features: [
                  "Set up audit trail automation",
                  "Document control processes",
                  "Implement access management",
                  "Establish version control systems"
                ],
                potential: "Can help reduce reporting errors by up to 95% while maintaining complete audit trails."
              }
            ].map((service, index) => (
              <div key={index} className="bg-gray-100 dark:bg-gray-800 p-6 rounded-lg shadow-md">
                <div className="flex items-center mb-4">
                  <div className="bg-blue-100 dark:bg-blue-900 p-2 rounded-full mr-4">
                    <service.icon className="h-6 w-6 text-blue-600 dark:text-blue-400" />
                  </div>
                  <h3 className="text-xl font-semibold text-gray-900 dark:text-white">{service.title}</h3>
                </div>
                <p className="text-gray-600 dark:text-gray-300 mb-4">{service.description}</p>
                <ul className="list-disc list-inside text-gray-600 dark:text-gray-300 mb-4">
                  {service.features.map((feature, fIndex) => (
                    <li key={fIndex} className="mb-2">{feature}</li>
                  ))}
                </ul>
                <p className="text-sm italic text-gray-500 dark:text-gray-400">
                  <strong>Opportunity:</strong> {service.potential}
                </p>
              </div>
            ))}
            </div>
          </div>
        </section>
      </AnimatedSection>

      

      {/* New Section: Our Tools */}
      <AnimatedSection id="tools">
        <section className="py-16 bg-gray-100 dark:bg-gray-800">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <h2 className="text-3xl font-bold text-center mb-4 text-gray-900 dark:text-white">Tools We Leverage</h2>
            <p className="text-center text-gray-600 dark:text-gray-300 mb-12 max-w-2xl mx-auto">
              We combine industry-leading tools with our expertise to deliver powerful, tailored solutions that drive your business forward.
            </p>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-6">
            {[
              { 
                name: "Excel & VBA", 
                icon: FileSpreadsheet, 
                color: "from-green-400 to-green-600",
                bgColor: "bg-green-100 dark:bg-green-900/30",
                iconColor: "text-green-600 dark:text-green-400",
                description: "Advanced automation & modeling"
              },
              { 
                name: "Power BI", 
                icon: ChartBar, 
                color: "from-yellow-400 to-orange-500",
                bgColor: "bg-orange-100 dark:bg-orange-900/30",
                iconColor: "text-orange-600 dark:text-orange-400",
                description: "Interactive dashboards"
              },
              { 
                name: "Power Automate", 
                icon: Cog, 
                color: "from-blue-400 to-indigo-500",
                bgColor: "bg-blue-100 dark:bg-blue-900/30",
                iconColor: "text-blue-600 dark:text-blue-400",
                description: "Workflow automation"
              },
              { 
                name: "SQL Databases", 
                icon: Database, 
                color: "from-purple-400 to-purple-600",
                bgColor: "bg-purple-100 dark:bg-purple-900/30",
                iconColor: "text-purple-600 dark:text-purple-400",
                description: "Data management"
              },
              { 
                name: "Python", 
                icon: Code, 
                color: "from-cyan-400 to-blue-500",
                bgColor: "bg-cyan-100 dark:bg-cyan-900/30",
                iconColor: "text-cyan-600 dark:text-cyan-400",
                description: "Advanced analytics"
              },
              { 
                name: "SAP", 
                icon: BarChart, 
                color: "from-red-400 to-red-600",
                bgColor: "bg-red-100 dark:bg-red-900/30",
                iconColor: "text-red-600 dark:text-red-400",
                description: "Enterprise integration"
              },
              { 
                name: "Oracle", 
                icon: PieChart, 
                color: "from-rose-400 to-pink-600",
                bgColor: "bg-rose-100 dark:bg-rose-900/30",
                iconColor: "text-rose-600 dark:text-rose-400",
                description: "Financial systems"
              },
              { 
                name: "Tableau", 
                icon: TrendingUp, 
                color: "from-teal-400 to-teal-600",
                bgColor: "bg-teal-100 dark:bg-teal-900/30",
                iconColor: "text-teal-600 dark:text-teal-400",
                description: "Visual analytics"
              },
            ].map((tool, index) => (
              <div 
                key={index} 
                className="group relative bg-white dark:bg-gray-900 p-6 rounded-xl shadow-md hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1"
              >
                <div className={`absolute inset-0 bg-gradient-to-br ${tool.color} opacity-0 group-hover:opacity-10 rounded-xl transition-opacity duration-300`}></div>
                <div className="relative flex flex-col items-center">
                  <div className={`p-3 rounded-lg ${tool.bgColor} mb-4`}>
                    <tool.icon className={`h-8 w-8 ${tool.iconColor}`} />
                  </div>
                  <h3 className="text-lg font-semibold text-gray-800 dark:text-gray-200 mb-2 text-center">
                    {tool.name}
                  </h3>
                  <p className="text-sm text-gray-600 dark:text-gray-400 text-center">
                    {tool.description}
                  </p>
                </div>
              </div>
            ))}
            </div>
          </div>
        </section>
      </AnimatedSection>

      

      {/* Industries Section */}
      <AnimatedSection id="industries">
        <section className="py-16 bg-gray-100 dark:bg-gray-800">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <h2 className="text-3xl font-bold text-center mb-12 text-gray-900 dark:text-white">Industry Expertise</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {[
              { 
                icon: ShoppingBag, 
                title: "Retail", 
                description: "Optimize inventory management and sales forecasting",
                focusAreas: [
                  "Store P&L automation",
                  "SKU-level margin & inventory turnover analysis",
                  "Dynamic pricing & promotion impact modeling",
                  "Customer lifetime value & basket analysis"
                ]
              },
              { 
                icon: Globe, 
                title: "Wholesale", 
                description: "Optimize distribution networks and margin management",
                focusAreas: [
                  "Product-mix profitability by region/customer",
                  "Logistics cost allocation & route optimization",
                  "Volume-based customer discount analysis",
                  "Working capital & inventory aging tracking"
                ]
              },
              { 
                icon: Tv, 
                title: "TMT", 
                description: "Enhance project-based accounting and revenue recognition",
                focusAreas: [
                  "ARPU, CAC, CLTV, Churn Rate",
                  "Project margin & resource utilization",
                  "IFRS15, ASC606, ASC842",
                  "CAPEX",
                  "R&D capitalization tracking"
                ]
              },
              { 
                icon: Apple, 
                title: "FMCG", 
                description: "Improve supply chain finance and trade promotion analysis",
                focusAreas: [
                  "Trade spend ROI by SKU/customer",
                  "Product matrix profitability (per SKU, KG, M3)",
                  "Inventory turnover & aging",
                  "Retail and competitor pricing analysis"
                ]
              }
              ].map((industry, index) => (
                <div key={index} className="flex flex-col h-full bg-white dark:bg-gray-900 p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300">
                  <div className="flex flex-col items-center text-center mb-6">
                    <div className="bg-blue-100 dark:bg-blue-900/30 p-3 rounded-full mb-4">
                      <industry.icon className="h-8 w-8 text-blue-600 dark:text-blue-400" />
                    </div>
                    <h3 className="text-xl font-semibold mb-3 text-gray-900 dark:text-white">{industry.title}</h3>
                    <p className="text-gray-600 dark:text-gray-300">{industry.description}</p>
                  </div>
                  
                  <div className="mt-auto">
                    <div className="border-t border-gray-200 dark:border-gray-700 pt-4">
                      <h4 className="font-semibold text-gray-900 dark:text-white mb-3">Focus Areas:</h4>
                      <ul className="space-y-2">
                        {industry.focusAreas.map((area, idx) => (
                          <li key={idx} className="flex items-start text-sm text-gray-600 dark:text-gray-400">
                            <span className="text-blue-600 dark:text-blue-400 mr-2">•</span>
                            <span>{area}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </AnimatedSection>

     
      {/* Updated About Section */}
      <AnimatedSection id="about">
        <section className="py-16 bg-white dark:bg-gray-900">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <h2 className="text-3xl font-bold text-center mb-12 text-gray-900 dark:text-white">Why Choose Hermes BI</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
              <div>
                <div className="space-y-6">
                  <div className="border-l-4 border-blue-600 pl-4">
                    <h3 className="text-xl font-semibold mb-2 text-gray-900 dark:text-white">ROI-Driven Approach</h3>
                    <p className="text-gray-600 dark:text-gray-300">
                      Every automation we implement is measured against its financial impact. Whether it's direct cost savings or enabling faster, data-driven decisions that boost your bottom line - we ensure measurable returns on your investment.
                    </p>
                  </div>
                  
                  <div className="border-l-4 border-green-600 pl-4">
                    <h3 className="text-xl font-semibold mb-2 text-gray-900 dark:text-white">Long-Term Partnership</h3>
                    <p className="text-gray-600 dark:text-gray-300">
                      We believe in growing together. Our engagement model focuses on progressive improvement - from quick wins to advanced analytics, we're here to support your journey towards data excellence at every stage.
                    </p>
                  </div>

                  <div className="border-l-4 border-purple-600 pl-4">
                    <h3 className="text-xl font-semibold mb-2 text-gray-900 dark:text-white">Passion for Data</h3>
                    <p className="text-gray-600 dark:text-gray-300">
                      We're passionate about transforming raw data into actionable insights. Our mission is to empower your business decisions with reliable, timely, and meaningful analytics that drive real results.
                    </p>
                  </div>
                </div>
              </div>

              <div className="bg-gray-100 dark:bg-gray-800 p-6 rounded-lg shadow-md">
                <h3 className="text-xl font-semibold mb-6 text-gray-900 dark:text-white">Our Experience & Approach</h3>
                <div className="space-y-6">
                  {[
                    {
                      icon: Brain,
                      title: 'Pragmatic Solutions',
                      description: 'We prioritize value over form, adapting our solutions to your specific context and needs'
                    },
                    {
                      icon: GitBranch,
                      title: 'Cross-Industry Expertise',
                      description: 'Deep experience across various industries and reporting types, enabling flexible and proven solutions'
                    },
                    {
                      icon: Shield,
                      title: 'Risk Management',
                      description: 'Careful consideration of data quality, security, and process reliability in every solution'
                    },
                    {
                      icon: Users,
                      title: 'Change Management',
                      description: 'Focus on user adoption and practical implementation that works for your team'
                    }
                  ].map((item, index) => (
                    <div key={index} className="flex items-start">
                      <div className="bg-white dark:bg-gray-900 p-2 rounded-lg">
                        <item.icon className="h-6 w-6 text-blue-600 dark:text-blue-400" />
                      </div>
                      <div className="ml-4">
                        <h4 className="font-semibold text-gray-900 dark:text-white">{item.title}</h4>
                        <p className="text-sm text-gray-600 dark:text-gray-400">{item.description}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
      </AnimatedSection>

      <AnimatedSection id="contact">
        <section className="py-16 bg-gray-100 dark:bg-gray-800">
          <div className="text-center py-16">
            <h2 className="text-4xl font-bold mb-8 text-gray-900 dark:text-white bg-gradient-to-r from-blue-600 to-indigo-600 bg-clip-text text-transparent">
              Let's Discuss Your Financial Processes
            </h2>
            <div className="max-w-2xl mx-auto">
              <p className="text-xl mb-4 text-gray-700 dark:text-gray-300 font-medium">
                Schedule a free 30-minute discovery call to explore:
              </p>
              <ul className="space-y-4 text-lg text-gray-600 dark:text-gray-400">
                <li className="flex items-center justify-center">
                  <span className="text-blue-600 dark:text-blue-400 mr-2">•</span>
                  Your current challenges with financial reporting and data flow
                </li>
                <li className="flex items-center justify-center">
                  <span className="text-blue-600 dark:text-blue-400 mr-2">•</span>
                  Potential areas for process improvement
                </li>
                <li className="flex items-center justify-center">
                  <span className="text-blue-600 dark:text-blue-400 mr-2">•</span>
                  How our expertise might benefit your team
                </li>
              </ul>
            </div>
            <div className="mt-12">
              <CalWidget />
            </div>
          </div>
        </section>
      </AnimatedSection>

      {/* Footer */}
      <footer className="bg-gray-800 dark:bg-gray-900 text-white py-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
            <div>
              <h3 className="text-lg font-semibold mb-4">Hermes BI</h3>
              <p className="text-gray-400">Swift financial intelligence for modern businesses.</p>
            </div>
            <div>
              <h3 className="text-lg font-semibold mb-4">Quick Links</h3>
              <ul className="space-y-2">
                {['Home', 'Services', 'Industries', 'About', 'Contact'].map((item, index) => (
                  <li key={index}>
                    <a
                      href={`#${item.toLowerCase()}`}
                      onClick={(e) => {
                        e.preventDefault();
                        scrollTo(item.toLowerCase());
                      }}
                      className="text-gray-400 hover:text-white transition duration-300"
                    >
                      {item}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <div>
              <h3 className="text-lg font-semibold mb-4">Contact Us</h3>
              <ul className="space-y-2">
                <li className="flex items-center text-gray-400">
                  <Mail className="h-5 w-5 mr-2" /> info@hermesbi.com
                </li>
            
                <li className="flex items-center text-gray-400">
                  <MapPin className="h-5 w-5 mr-2" /> Amsterdam, Netherlands
                </li>
              </ul>
            </div>
            <div>
              <h3 className="text-lg font-semibold mb-4">Stay Updated</h3>
              <p className="text-gray-400 mb-2">Subscribe to our newsletter for the latest updates and insights.</p>
              <EmailSubscriptionForm />
            </div>
          </div>
          <div className="mt-8 pt-8 border-t border-gray-700 text-center text-gray-400">
            <p>&copy; {new Date().getFullYear()} Hermes Business Intelligence. All rights reserved.</p>
          </div>
        </div>
      </footer>

     <YandexMetrika />
     <GoogleTagManager />
    </div>
  );
};

export default LandingPage;