import React, { useState, useEffect } from 'react';
import { Zap, Clock, CheckCircle, Trophy } from 'lucide-react';

interface HeroSectionProps {
  onScrollTo: (id: string) => void;
}

const HeroSection: React.FC<HeroSectionProps> = ({ onScrollTo }) => {
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const [isCurtainOpen, setIsCurtainOpen] = useState(false);

  useEffect(() => {
    const video = document.getElementById('background-video') as HTMLVideoElement;
    if (video) {
      const handleVideoLoad = () => {
        setIsVideoLoaded(true);
        setTimeout(() => setIsCurtainOpen(true), 500);
      };
      
      video.addEventListener('loadeddata', handleVideoLoad);
      return () => video.removeEventListener('loadeddata', handleVideoLoad);
    }
  }, []);

  return (
    <div className="relative min-h-screen overflow-hidden bg-gradient-to-r from-blue-600 to-indigo-700 dark:from-blue-800 dark:to-indigo-900">
      {/* Background Image (shown while video is loading) */}
      <div 
        className="absolute inset-0 bg-cover bg-center transition-opacity duration-1000"
        style={{
          backgroundImage: 'url("/hero.jpg")',
          opacity: isVideoLoaded ? 0 : 1,
        }}
      />

      {/* Video Background */}
      <video
        id="background-video"
        className="absolute inset-0 w-full h-full object-cover transition-opacity duration-1000"
        autoPlay
        loop
        muted
        playsInline
        style={{ opacity: isVideoLoaded ? 1 : 0 }}
      >
        <source src="/hermes-bi.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      {/* Gradient Overlay */}
      <div 
        className="absolute inset-0 bg-gradient-to-r from-blue-900/90 to-blue-900/40"
        style={{ mixBlendMode: 'multiply' }}
      />

      {/* Curtain Animation */}
      <div 
        className={`absolute inset-0 bg-blue-900 transition-transform duration-1000 ease-in-out ${
          isCurtainOpen ? 'translate-y-full' : ''
        }`}
      />

      {/* Content */}
      <div className="relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 min-h-screen flex items-center">
        <div className="w-full py-16 md:py-24">
          <div className="max-w-3xl">
            <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold mb-6 text-white">
              Transform Your Financial Reporting From{' '}
              <span className="text-blue-300">Time-Consuming</span>{' '}
              to{' '}
              <span className="text-blue-300">Time-Saving</span>
            </h1>
            
            <p className="text-xl md:text-2xl text-gray-200 mb-8">
              Helping Financial Leaders reduce reporting time by 70% through intelligent automation 
              and process optimization.
            </p>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-8">
              <div className="flex items-center bg-white/10 backdrop-blur-sm p-4 rounded-lg">
                <CheckCircle className="h-6 w-6 text-green-400 mr-3 flex-shrink-0" />
                <span className="text-white">60+ companies analyzed in M&A</span>
              </div>
              <div className="flex items-center bg-white/10 backdrop-blur-sm p-4 rounded-lg">
                <Clock className="h-6 w-6 text-blue-400 mr-3 flex-shrink-0" />
                <span className="text-white">70% reporting time reduction</span>
              </div>
              <div className="flex items-center bg-white/10 backdrop-blur-sm p-4 rounded-lg">
                <Trophy className="h-6 w-6 text-yellow-400 mr-3 flex-shrink-0" />
                <span className="text-white">Ex-Big4 Team</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;